<template>
  <div class="book_item my_pointer">
    <img class="book_img" v-if="imgUrl" :src="imgUrl + bookItem.img_url" alt />
    <div class="book_right">
      <div class="book_name">{{ bookItem.title }}</div>
      <div class="book_study">{{ bookItem.study_num }}人学习</div>
      <div class="btn_study">立即学习</div>
    </div>
  </div>
</template>

<script>
import { PUBLIC_DATA } from '../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  props: {
    bookItem: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      imgUrl
    };
  }
};
</script>

<style lang="less" scoped>
.book_item {
  height: 180px;
  display: flex;
  background: #ffffff;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  .book_img {
    width: 105px;
  }
  .book_right {
    flex: 2;
    padding-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .book_name {
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .book_study {
      font-size: 14px;
      color: #faad14;
    }
    .btn_study {
      font-size: 14px;
      width: 88px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      background: #1890ff;
      color: white;
      text-align: center;
    }
  }
}
</style>
