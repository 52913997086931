<template>
  <a-drawer
    :title="title"
    :closable="false"
    @close="onClose"
    :visible="visible"
    :placement="placement"
    :width="width"
    :bodyStyle="bodyStyle"
  >
    <div class="inner_card_content">
      <my-loading v-show="loading"></my-loading>
      <slot></slot>
    </div>
    <div class="card_footer" v-if="!hideFooter">
      <a-button style="marginRight: 8px" @click="onClose">
        取消
      </a-button>
      <a-button @click="onSubmit" type="primary">
        确定
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import MyLoading from '../../components/MyLoading';
export default {
  components: { MyLoading },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '标题'
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: 'right'
    },
    zIndex: {
      type: Number,
      default: 1000
    },
    width: {
      type: [String, Number],
      default: 500
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      childrenDrawer: false,
      bodyStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '0px',
        height: 'calc(100% - 55px)',
        userSelect: 'none'
      }
    };
  },
  methods: {
    onClose() {
      this.$emit('onCancale');
    },

    onSubmit() {
      this.$emit('onOk');
    }
  }
};
</script>
<style lang="less" scoped>
.inner_card_content {
  flex: 2;
  overflow-y: scroll;
  position: relative;
}
.card_footer {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
