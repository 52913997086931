<template>
  <div class="outer_page_container">
    <MyBreadCrumb :breadMenu="breadMenu"></MyBreadCrumb>
    <div class="book_container">
      <a-row :gutter="24">
        <a-col :span="24">
          <a-card
            :title="bookInfo.title"
            :bodyStyle="{ padding: '20px', display: 'flex' }"
          >
            <img
              class="book_img"
              alt="example"
              v-if="imgUrl"
              :src="imgUrl + bookInfo.img_url"
            />
            <div class="right_box">
              <div class="book_name">{{ bookInfo.title }}</div>
              <div class="book_study">{{ bookInfo.study_num }}人已学习</div>
              <div class="book_btn">{{ freeTypeText[bookInfo.is_free] }}</div>
              <div class="book_desc">{{ bookInfo.content }}</div>
            </div>
            <a-button type="primary" ghost @click="toggleTextBook" slot="extra">
              切换教材
            </a-button>
          </a-card>
        </a-col>
      </a-row>

      <a-card class="content_card" :bodyStyle="{ height: '100%' }">
        <a-tabs v-if="false">
          <a-tab-pane tab="内容" key="1"></a-tab-pane>
          <a-tab-pane tab="排行榜" key="2"></a-tab-pane>
          <a-tab-pane tab="简介" key="3"></a-tab-pane>
        </a-tabs>

        <div>
          <a-tabs @change="tabTitleChange" :activeKey="currentChapterId">
            <a-tab-pane
              v-for="tabItem in currentBookChapter"
              :tab="tabItem.title"
              :key="tabItem.id"
            ></a-tab-pane>
            <!-- 有单元时候 -->
            <div v-if="isUnit" slot="tabBarExtraContent">
              <a-dropdown>
                <a class="ant-dropdown-link">
                  切换章节
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay" v-if="!isUnit" @click="menuItemClick">
                  <a-menu-item v-for="item in allChapterArr" :key="item.id">
                    {{ item.name }}
                  </a-menu-item>
                </a-menu>
                <a-menu
                  slot="overlay"
                  v-else
                  :selectedKeys="[currentChapterId]"
                >
                  <a-sub-menu
                    v-for="subItem in allChapterArr"
                    :key="subItem.id"
                    :title="subItem.title"
                  >
                    <a-menu-item
                      v-for="menuItem in subItem.sub_chapters"
                      :key="menuItem.id"
                      @click="subMenuItemClick(subItem.id, menuItem.id)"
                    >
                      {{ menuItem.title }}
                    </a-menu-item>
                  </a-sub-menu>
                </a-menu>
              </a-dropdown>
            </div>
          </a-tabs>
          <a-list
            class="demo-loadmore-list"
            itemLayout="horizontal"
            :dataSource="contentArr"
            :loading="listLoading"
          >
            <!-- 是多个类别时候 -->
            <a-list-item
              slot="renderItem"
              slot-scope="item"
              v-if="!isSingleCategory"
            >
              <span slot="actions">{{ item.lastStudy }}</span>
              <a slot="actions" @click="toLearn(item)">继续学习</a>
              <a
                slot="actions"
                v-if="item.tag === 'exam' || item.tag === 'reading'"
                @click="toLearnReport(item)"
              >
                学习报告
              </a>
              <a-list-item-meta :description="item.name">
                <a slot="title">{{ item.name.last }}</a>
              </a-list-item-meta>
            </a-list-item>
            <!-- 单个类别时 -->
            <a-list-item slot="renderItem" slot-scope="item" v-else>
              <a slot="actions" @click="toSingleLearn(item)">继续学习</a>
              <a-list-item-meta :description="item | RateForamt">
                <a class="game_title" slot="title">{{ item.name }}</a>
                <span slot="avatar" :class="item.img_url | iconFormat"></span>
                <a-icon slot="avatar" type="vertical-left" />
              </a-list-item-meta>
              <star :star="item.rate * 100" />
            </a-list-item>
          </a-list>
        </div>
      </a-card>

      <my-drawer
        @onCancale="clickCancel"
        @onOk="clickOk"
        :visible="modalVisible"
        :loading="modalLoading"
        :width="500"
        :hideFooter="true"
      >
        <div class="inner_modal_content">
          <a-tabs @change="firstNavChange" :defaultActiveKey="currentNavId">
            <a-tab-pane
              :tab="item.name"
              v-for="item in firstNavList"
              :key="item.id"
            ></a-tab-pane>
          </a-tabs>
          <a-tabs
            v-if="secondNavList.length > 0"
            @change="secondNavChange"
            :defaultActiveKey="secondNavId"
          >
            <a-tab-pane
              :tab="item.name"
              v-for="item in secondNavList"
              :key="item.id"
            ></a-tab-pane>
          </a-tabs>
          <a-row class="book_list_container" :gutter="24">
            <no-data v-if="bookList.length === 0"></no-data>
            <a-col
              :span="24"
              v-for="bookItem in bookList"
              @click="chooseBookItem(bookItem)"
              :key="bookItem.id"
            >
              <book-item :bookItem="bookItem"></book-item>
            </a-col>
          </a-row>
        </div>
      </my-drawer>
    </div>
  </div>
</template>

<script>
import {
  getBookInfo,
  getBookCatalogList,
  fetchExamRate,
  resetExamRate,
  getStartPageMenu,
  getBookPress,
  getBookList,
  fetchBookGameList,
  gameUserQuestionRate,
  resetUserQuestionRate,
  getReadingRate,
  resetReadingRate
} from '../../api/service';
import Star from '../../components/Star';
import MyBreadCrumb from '../../components/BreadCrumb';
import { PUBLIC_DATA, showMyModal, showMyToast } from '../../utils/common';
import MyDrawer from '@/components/MyDrawer';
import BookItem from '../../components/BookItem';
import NoData from '../../components/NoData';
const { imgUrl } = PUBLIC_DATA;
export default {
  inject: ['reload'],

  created() {
    this.bookId = this.$route.params.bookId;
    this.fetchBookInfo();
  },

  components: { MyDrawer, BookItem, NoData, MyBreadCrumb, Star },

  filters: {
    RateForamt(item) {
      switch (Number(item.rate)) {
        case 0:
          return '未练习';
        default:
          return `已完成${item.formatRate}`;
      }
    },
    iconFormat(item) {
      return `anticon-yyashow game_icon anticon-${item}`;
    }
  },

  data() {
    return {
      bookId: '',
      bookInfo: {},
      imgUrl: imgUrl,
      // 当前单元id
      currentUnitId: -1,
      // 当前章节id
      currentChapterId: '',
      // 当前章节
      chapterArr: [],
      // 单元加章节
      bookCatalog: [],
      // 当前章节数组
      currentBookChapter: [],
      // 是否含有单元
      isUnit: false,
      // 内容
      contentArr: [],
      isSingleCategory: false,
      listLoading: true,
      freeTypeText: {
        0: '免费课本',
        1: '收费课本',
        2: '会员课本',
        3: 'VIP课本'
      },
      // 模态框显示层
      modalVisible: false,
      firstNavList: [],
      currentNavId: '',
      secondNavId: '',
      secondNavList: [],
      modalLoading: false,
      bookList: [],
      breadMenu: [
        {
          title: '选择教材',
          path: '/book'
        },
        {
          title: '教材详情'
        }
      ]
    };
  },

  methods: {
    // 一级分类切换
    firstNavChange(event) {
      this.modalLoading = true;
      this.currentNavId = event;
      this.fetchSecondList();
    },

    // 二级分类切换
    secondNavChange(event) {
      this.modalLoading = true;
      this.secondNavId = event;
      this.fetchBookList();
    },

    // 切换教材
    toggleTextBook() {
      this.modalLoading = true;
      getStartPageMenu().then(response => {
        const data = response.data;
        if (data.length === 0) {
          showMyToast('w', '暂无书籍');
          this.modalLoading = false;
          return;
        }
        this.firstNavList = data;
        this.currentNavId = data[0].id;
        this.fetchSecondList();
      });
      this.modalVisible = true;
    },

    fetchSecondList() {
      getBookPress({ partId: this.currentNavId }).then(response => {
        const { data } = response;
        if (data.length <= 0) {
          this.secondNavId = 0;
          this.fetchBookList();
          return;
        }
        this.secondNavId = data[0].id;
        this.secondNavList = data;
        this.fetchBookList();
      });
    },

    // 获取图书列表
    fetchBookList() {
      getBookList({
        partId: this.currentNavId,
        pressId: this.secondNavId
      })
        .then(response => {
          this.modalLoading = false;
          this.bookList = response.data;
        })
        .catch(() => {
          this.modalLoading = false;
        });
    },
    // 点击某本书
    chooseBookItem(item) {
      this.$router.replace(`/book/${item.id}`);
      this.reload();
    },

    clickCancel() {
      this.modalVisible = false;
    },

    clickOk() {
      this.modalVisible = false;
    },

    // 算出当前单元下的章节
    getCurrentBookChapter() {
      const currentBookChapterArr = this.allChapterArr.filter(
        item => Number(item.id) === Number(this.currentUnitId)
      );
      return currentBookChapterArr.length > 0
        ? currentBookChapterArr[0].sub_chapters
        : [];
    },

    // 子标题点击事件
    menuItemClick(event) {
      console.log('menuItemClick');
      console.log(event);
    },

    // 多级菜单点击(存在单元)
    subMenuItemClick(unitId, chapterId) {
      this.currentChapterId = chapterId;
      this.currentUnitId = unitId;
      this.currentBookChapter = this.getCurrentBookChapter();
      this.fetchBookContent();
    },

    fetchBookInfo() {
      getBookInfo({ bookId: this.bookId }).then(response => {
        const bookInfo = response.data;
        this.bookInfo = bookInfo;
        const {
          chapter,
          book_catalog: bookdCatalog,
          current_chapter_id: chapterId,
          current_unit_id: unitId,
          is_show_game_order: isShowGameOrder
        } = bookInfo;

        localStorage.setItem(
          'is_show_game_order_' + this.bookId,
          isShowGameOrder
        );
        // 当前章节
        this.currentChapterId = chapterId;
        if (chapter.length > 0) {
          this.isUnit = false;
          this.allChapterArr = chapter;
          this.currentBookChapter = chapter;
          this.fetchBookContent();
          return;
        }
        // 存在单元
        this.isUnit = true;
        this.allChapterArr = bookdCatalog;
        this.currentUnitId = unitId;
        this.currentBookChapter = this.getCurrentBookChapter();
        this.fetchBookContent();
      });
    },

    fetchBookContent() {
      this.listLoading = true;
      getBookCatalogList({
        bookId: this.bookId,
        bookChapterId: this.currentChapterId
      })
        .then(response => {
          const { data } = response;
          const { tag } = data[0] || {};
          if (data.length === 1 && tag !== 'reading' && tag !== 'exam') {
            this.singleTag = tag;
            this.fetchGameListContent(data[0].id);
            return;
          }
          this.isSingleCategory = false;
          this.contentArr = response.data;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },

    // 获取游戏列表数据
    fetchGameListContent(typeId) {
      this.singleTypeId = typeId;
      fetchBookGameList({
        bookId: this.bookId,
        type: typeId,
        bookChapterId: this.currentChapterId
      }).then(response => {
        // 是否是单独类别
        this.isSingleCategory = true;
        this.contentArr = response.data;
        this.listLoading = false;
      });
    },

    // 标题切换
    tabTitleChange(event) {
      this.currentChapterId = event;
      this.fetchBookContent();
    },

    // 继续学习
    toLearn(item) {
      const { id, tag, source } = item;
      // 是综合训练
      switch (tag) {
        case 'exam':
          this.handleWithExam({ id, source });
          break;
        case 'word':
        case 'text':
          this.$router.push(
            `/book/study/play/${this.bookId}/${this.currentChapterId}/${id}/${this.currentUnitId}`
          );
          break;
        case 'reading':
          this.handleWithReading(item);
          break;
        default:
          showMyToast('w', '开发中，敬请期待');
          break;
      }
    },
    // 去学习某个玩法
    toSingleLearn(item) {
      switch (this.singleTag) {
        case 'word':
        case 'text':
          this.handleWithWord(item, this.singleTag);
          break;
        // case 'reading':
        //   this.handleWithReading(item, this.singleTag);
        //   break;
        default:
          showMyToast('w', '开发中，敬请期待');
          return;
      }
    },

    // 处理word
    handleWithWord(item, singleTag) {
      const { template_path: templatePath, id: gameId, name } = item;
      const typeObj = {
        word: 'word',
        text: 'text'
      };
      const that = this;
      const afterTemplate = templatePath
        .replace('/pages/study/', '')
        .replace('/main', '');
      const jumpUrl = `/book/study/${typeObj[singleTag]}/${afterTemplate}/${this.bookId}/${this.currentChapterId}/${this.singleTypeId}/${gameId}/:${name}`;
      gameUserQuestionRate({
        gameId: gameId,
        bookChapterId: that.currentChapterId
      }).then(response => {
        const { rate } = response.data;
        if (Number(rate) >= 1) {
          showMyModal('你已学完,点击确定将重新开始', () => {
            resetUserQuestionRate({
              bookChapterId: that.currentChapterId,
              gameId: gameId
            }).then(() => {
              this.$router.push(jumpUrl);
            });
          });
          return;
        }
        this.$router.push(jumpUrl);
      });
    },
    // 处理
    handleWithReading(item) {
      // 如果学完需要给到提示
      const { id: typeId } = item;
      const that = this;
      const jumpUrl = `/book/study/read/${this.bookId}/${this.currentChapterId}/${typeId}`;
      console.log(jumpUrl);
      getReadingRate({
        bookChapterId: that.currentChapterId,
        tag: 'reading'
      }).then(response => {
        const { rate } = response.data;
        if (Number(rate) >= 1) {
          showMyModal('你已学完,点击确定将重新开始', () => {
            resetReadingRate({
              bookChapterId: that.currentChapterId,
              tag: 'reading'
            }).then(() => {
              this.$router.push(jumpUrl);
            });
          });
          return;
        }
        this.$router.push(jumpUrl);
      });
    },

    // 处理exam
    handleWithExam({ id, source }) {
      const that = this;
      const examUrl = `/exam/${this.bookId}/${this.currentChapterId}/${id}/${source}`;
      // if (Number(source) === 4) {
      //   this.$router.push(examUrl);
      //   return;
      // }

      const defaultGetRateUrl =
        Number(source) == 4
          ? 'question/exam/source-rate'
          : 'question/exam/get-rate';

      fetchExamRate(defaultGetRateUrl, {
        bookChapterId: this.currentChapterId,
        type: id,
        typeId: id,
        source: source
      }).then(response => {
        const { rate } = response.data;
        if (Number(rate) >= 1) {
          showMyModal('你已学完,点击确定将重新开始', () => {
            resetExamRate({
              bookChapterId: that.currentChapterId,
              source: source,
              typeId: id
            }).then(() => {
              this.$router.push(examUrl);
            });
          });
          return;
        }
        this.$router.push(examUrl);
      });
    },

    // 学校报告
    toLearnReport(item) {
      const { source, id: typeId, tag } = item;
      this.$router.push(
        `/report/${tag}/${this.currentChapterId}/${source}/${typeId}`
      );
    }
  }
};
</script>

<style lang="less" scoped>
.outer_page_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.book_container {
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  .content_card {
    flex: 2;
    margin-top: 20px;
  }
  .book_img {
    height: 215px;
  }
  .right_box {
    margin-left: 20px;
    .book_name {
      font-size: 16px;
      color: #333333;
      margin-bottom: 20px;
    }
    .book_study {
      font-size: 14px;
      color: #faad14;
      margin-bottom: 20px;
    }
    .book_btn {
      display: inline-block;
      padding: 0 8px;
      background: #e6f7ff;
      border: 1px solid #91d5ff;
      border-radius: 4px;
      font-size: 12px;
      color: #1890ff;
      text-align: left;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .book_desc {
      font-size: 14px;
      color: #333333;
    }
  }
}
.inner_modal_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  .book_list_container {
    position: relative;
    flex: 2;
    overflow-y: scroll;
  }
}
.game_icon {
  font-size: 55px;
  color: #1890ff;
}
.game_title {
  font-size: 18px;
  color: #1890ff;
}
</style>
